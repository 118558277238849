import "styles/pages/page-home.scss";
import "react-image-lightbox/style.css";

import React, { useState } from "react";
import { Link, graphql } from "gatsby";

import Lightbox from "react-image-lightbox";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazy-load";

import Layout from "components/Layout";
import Seo from "components/Seo";
import HomeTile from "components/HomeTile";
import Divider from "components/Divider";

import ZoomWrapper from "components/ZoomWrapper";
import PsychologySlider from "components/PsychologySlider";
import ReviewSection from "components/ReviewSection";
import HomeBlogSection from "components/HomeBlogSection";

const HomePage = ({ data }) => {
  const homeData = data.pageHome.nodes[0].pageHome;
  const seo = data.pageHome.nodes[0].seo;

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const gallery = homeData.homeGallery?.map(
    (item) => item.homeGalleryItem?.sourceUrl
  );

  const galleryBackgroundImage = [];
  function createBackgroundImageVariables() {
    for (let i = 0; i <= 5; ++i) {
      galleryBackgroundImage[i] = {
        backgroundImage: `url(${homeData.homeGallery?.[i].homeGalleryItem?.sourceUrl})`,
      };
    }
  }
  createBackgroundImageVariables();

  return (
    <Layout showSchema>
      <Seo title={seo.title} description={seo.metaDesc} />
      <section className="home-hero">
        <div className="container">
          {/* <StaticImage
            className="img-fluid"
            src={"../assets/images/psycholog-Wolomin-i-Warszawa-obraz.jpg"}
            alt=""
          /> */}
          <img
            className="img-fluid"
            src={
              require("assets/images/psycholog-Wolomin-i-Warszawa-obraz.jpg")
                .default
            }
            alt=""
          />
          <h1>Psycholog Wołomin i Warszawa</h1>
        </div>
      </section>

      <section className="home-tiles">
        <div className="container">
          <div className="home-tiles__tiles-row">
            {homeData?.hometileBox?.map((item, index) => (
              <HomeTile
                key={index}
                title={item.hometileTitle}
                color={item.hometileColor}
                img={item.hometileImg.sourceUrl}
                alt={item.hometileImg.altText}
                url={item.hometileLink}
                text={item.hometileText}
              />
            ))}
          </div>
        </div>
      </section>

      <section className="home-baner">
        <div className="container">
          <Link to="/testy-psychologiczne/">
            <div className="gatsby-image-center">
              <StaticImage
                className="desktop"
                src={"../assets/images/SwG_baner_www_desktop_3-v2.jpg"}
                alt=""
              />
            </div>
            <div className="gatsby-image-center">
              <StaticImage
                className="mobile"
                src={"../assets/images/SwG_baner_www_desktop_3-v2-mobile.jpg"}
                alt=""
              />
            </div>
          </Link>
        </div>
      </section>

      <section className="home-tiles">
        <div className="container">
          <h2 className="home-last-news__title">Nowości w naszej poradni</h2>
          <div className="home-tiles__tiles-row">
            {homeData?.homeTilesSecond?.map((item, index) => (
              <HomeTile
                key={index}
                title={item.hometileTitle}
                color={item.hometileColor}
                img={item.hometileImg.sourceUrl}
                alt={item.hometileImg.altText}
                url={item.hometileLink}
                text={item.hometileText}
              />
            ))}
          </div>
        </div>
      </section>

      <HomeBlogSection />

      <section className="home-baner">
        <div className="container">
          <div className="row no-g g-0">
            <div className="col-md-6">
              <Link to="/trening-umiejetnosci-spolecznych/">
                <StaticImage
                  // className="desktop"
                  src={"../assets/images/TUS-dzieci-lewa-strona.jpg"}
                  alt=""
                />
                {/* <div className="gatsby-image-center">
                  <StaticImage
                    className="mobile"
                    src={"../assets/images/home-baner-left-mobile.jpg"}
                    alt=""
                  />
                </div> */}
              </Link>
            </div>
            <div className="col-md-6">
              <Link to="/tus-dla-doroslych/">
                <StaticImage
                  // className="desktop"
                  src={"../assets/images/TUS-dorosli-prawa-strona.jpg"}
                  alt=""
                />
                {/* <div className="gatsby-image-center">
                  <StaticImage
                    className="mobile"
                    src={"../assets/images/home-baner-right-mobile.jpg"}
                    alt=""
                  />
                </div> */}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="home-baner">
        <div className="container">
          <div className="row no-g g-0">
            <div className="col-md-6">
              <Link to="/motywacja-koncentracja-warsztaty/">
                <StaticImage
                  // className="desktop"
                  src={"../assets/images/TWWS-lewa-strona.jpg"}
                  alt=""
                />
                {/* <div className="gatsby-image-center">
                  <StaticImage
                    className="mobile"
                    src={"../assets/images/home-baner-left-2-mobile.jpg"}
                    alt=""
                  />
                </div> */}
              </Link>
            </div>
            <div className="col-md-6">
              <Link to="/trening-umiejetnosci-wychowawczych-dla-rodzicow/">
                <StaticImage
                  // className="desktop"
                  src={"../assets/images/TUW-prawa-strona.jpg"}
                  alt=""
                />
                {/* <div className="gatsby-image-center">
                  <StaticImage
                    className="mobile"
                    src={"../assets/images/home-baner-right-2-mobile.jpg"}
                    alt=""
                  />
                </div> */}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="home-baner">
        <div className="container">
          <div className="row no-g g-0">
            <div className="col-md-6">
              <Link to="/grupy-wsparcia/">
                <StaticImage
                  // className="desktop"
                  src={"../assets/images/GW-lewa-strona.jpg"}
                  alt=""
                />
                {/* <div className="gatsby-image-center">
                  <StaticImage
                    className="mobile"
                    src={"../assets/images/home-baner-left-2-mobile.jpg"}
                    alt=""
                  />
                </div> */}
              </Link>
            </div>
            <div className="col-md-6">
              <Link to="/krag-kobiet/">
                <StaticImage
                  // className="desktop"
                  src={"../assets/images/KK-prawa-strona.jpg"}
                  alt=""
                />
                {/* <div className="gatsby-image-center">
                  <StaticImage
                    className="mobile"
                    src={"../assets/images/home-baner-right-2-mobile.jpg"}
                    alt=""
                  />
                </div> */}
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="home-why-us">
        <div className="container">
          <h2 className="home-why-us__title">{homeData.homeTitle}</h2>
          <Divider marginTop={15} marginBottom={45} />
          <img
            src={require("assets/images/logo-big.svg").default}
            alt=""
            className="img-fluid home-why-us__logo"
          />

          <div className="home-why-us__content">
            <div dangerouslySetInnerHTML={{ __html: homeData.homeIntro }} />
            <h3>{homeData.homeHeading1}</h3>
            <img
              src={require("assets/images/logo-big.svg").default}
              alt=""
              className="img-fluid"
            />
            <div dangerouslySetInnerHTML={{ __html: homeData.homeText1 }} />
            <h3>{homeData.homeHeading2}</h3>
            <div dangerouslySetInnerHTML={{ __html: homeData.homeText2 }} />
            <h3>{homeData.homeHeading3} </h3>
            <div dangerouslySetInnerHTML={{ __html: homeData.homeText3 }} />
          </div>
        </div>
      </section>

      <ReviewSection />

      <section className="home-gallery">
        <div className="container">
          <h2>{homeData.galleryTitle}</h2>
          <Divider marginBottom={45} marginTop={15} />
          <div className="row g-0">
            <div className="col-md-4 col-8 home-gallery__col-1">
              <LazyLoad>
                <div
                  className="home-gallery__img home-gallery__img--1"
                  style={galleryBackgroundImage[0]}
                >
                  <ZoomWrapper
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLightboxOpen(true);
                      setPhotoIndex(0);
                    }}
                  />
                </div>
              </LazyLoad>
            </div>
            <div className="col-md-2 col-4 home-gallery__col-2">
              <LazyLoad>
                <div
                  className="home-gallery__img home-gallery__img--2"
                  style={galleryBackgroundImage[1]}
                >
                  <ZoomWrapper
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLightboxOpen(true);
                      setPhotoIndex(1);
                    }}
                  />
                </div>
              </LazyLoad>
              <LazyLoad>
                <div
                  className="home-gallery__img home-gallery__img--3"
                  style={galleryBackgroundImage[2]}
                >
                  <ZoomWrapper
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLightboxOpen(true);
                      setPhotoIndex(2);
                    }}
                  />
                </div>
              </LazyLoad>
            </div>
            <div className="col-md-4 col-8 home-gallery__col-3">
              <LazyLoad>
                <div
                  className="home-gallery__img home-gallery__img--4"
                  style={galleryBackgroundImage[3]}
                >
                  <ZoomWrapper
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLightboxOpen(true);
                      setPhotoIndex(3);
                    }}
                  />
                </div>
              </LazyLoad>
            </div>
            <div className="col-md-2 col-4 home-gallery__col-4">
              <LazyLoad>
                <div
                  className="home-gallery__img home-gallery__img--5"
                  style={galleryBackgroundImage[4]}
                >
                  <ZoomWrapper
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLightboxOpen(true);
                      setPhotoIndex(4);
                    }}
                  />
                </div>
              </LazyLoad>
              <LazyLoad>
                <div
                  className="home-gallery__img home-gallery__img--6"
                  style={galleryBackgroundImage[5]}
                >
                  <ZoomWrapper
                    onClick={(e) => {
                      e.preventDefault();
                      setIsLightboxOpen(true);
                      setPhotoIndex(5);
                    }}
                  />
                </div>
              </LazyLoad>
            </div>
          </div>
          <div className="home-gallery__button-container">
            <Link to="/galeria/" className="button button--green">
              Zobacz więcej zdjęć
            </Link>
          </div>
        </div>
      </section>

      <section className="home-psychology">
        <div className="container">
          <PsychologySlider news={homeData.postcard} />
        </div>
      </section>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex]}
          nextSrc={gallery[(photoIndex + 1) % gallery.length]}
          prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length]}
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </Layout>
  );
};

export const query = graphql`
  query {
    pageHome: allWpPage(filter: { title: { eq: "Home" } }) {
      nodes {
        seo {
          metaDesc
          metaKeywords
          title
        }
        slug
        title
        uri
        pageHome {
          hometileBox {
            hometileColor
            hometileImg {
              altText
              sourceUrl
            }
            hometileLink
            hometileText
            hometileTitle
          }
          homeTilesSecond {
            hometileColor
            hometileImg {
              altText
              sourceUrl
            }
            hometileLink
            hometileText
            hometileTitle
          }
          hero {
            heroTitle
            heroText
            heroBanner {
              altText
              sourceUrl
            }
            heroMini {
              altText
              sourceUrl
            }
          }
          cert {
            certItem {
              altText
              sourceUrl
            }
          }
          certTitle
          galleryTitle
          homeHeading1
          homeHeading2
          homeHeading3
          homeIntro
          homeText1
          homeText2
          homeText3
          homeTitle
          homeGallery {
            homeGalleryItem {
              altText
              sourceUrl
            }
          }
          postcard {
            postcardHeading
            postcardText
            postcardImg {
              altText
              sourceUrl
            }
          }
        }
      }
    }
  }
`;

export default HomePage;
